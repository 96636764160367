<template>
  <AccountContainer :busy="busy">
    <v-layout column>
      <div :class="[`auth-header__${role}`, 'auth-header']">
        <div v-if="$route.params.status">
          <h1 v-if="$route.params.status === 'success'">{{ roleName }} Account Verified</h1>
          <h1 v-if="$route.params.status === 'verify-error'">Account Verification Error</h1>
        </div>
        <h1 v-else>Plugzio {{ roleName }} Dashboard</h1>
        <p class="mt-2 mb-0">
          <span v-if="successPage">Please sign in with your new account</span>
          <span v-else-if="role == 'user'">Login to activate a device, monitor usage and access your wallet</span>
          <span v-else-if="role == 'owner'">Login to manage your devices, view analytics and access your wallets</span>
          <span v-else>For Customer Service</span>
        </p>
      </div>
      <v-alert id="error" :value="error" type="error" dismissible>{{ error_message }}</v-alert>
      <v-flex class="login-area">
        <v-form ref="loginForm" @submit="signIn()" v-model="valid">
          <v-text-field
            id="login-email"
            outline
            label="E-mail"
            :name="role + '-username'"
            ref="usernameBox"
            prepend-inner-icon="account_circle"
            v-model="login.client_id"
            :error="!!error"
            autocomplete="false"
            :rules="[rules.required]"
            @keyup.enter.stop="signIn()"
            @keydown.space.prevent
            required
          />
          <div id="login-password-container">
            <v-text-field
              id="login-password"
              outline
              label="Password"
              :name="role + '-password'"
              ref="passwordBox"
              prepend-inner-icon="vpn_key"
              :append-icon="show ? 'visibility' : 'visibility_off'"
              v-model="login.client_secret"
              :error="!!error"
              :type="show ? 'text' : 'password'"
              autocomplete="false"
              :rules="[rules.required]"
              @keyup.enter.stop="signIn()"
              @click:append="show = !show"
              required
            />
          </div>
          <v-flex
            v-if="isMobileApplication"
            items-center
            d-flex
            id="login-checkbox-container"
            mb-4
          >
            <v-checkbox
              class="mt-0 pt-0"
              color="green"
              v-model="rememberLoginCredential"
              hide-details
            />
            <span
              class="white-space__nowrap w-full justify-start d-flex items-center text"
            >
              Remember Login Credentials
            </span>
          </v-flex>
        </v-form>
        <v-btn id="sign-in" class="plugzio-button mb-3" outline round @click.stop.prevent="signIn()">SIGN IN</v-btn>
        <template v-if="role !== 'admin'">
          <div>
            <v-btn id="register-new-account" color="#0d920d" flat round @click="$router.push({ name: `${roleSetting.routePrefix}register` })">Register New Account</v-btn>
          </div>
          <div>
            <v-btn id="forgot-password" small color="#777777" flat round @click="$router.push({ name: `${roleSetting.routePrefix}forgot-password` })">Forgot Password</v-btn>
          </div>
          <div>
            <v-btn id="contact-us" small color="#777777" flat round @click="showIntercom">Contact Us</v-btn>
          </div>
        </template>
        <Policy class="mt-4" />
      </v-flex>
    </v-layout>
    <IntercomEmailForm />
  </AccountContainer>
</template>

<script>
import mixin from "./../mixins";
import AccountContainer from "./../containers/Account";
import RouteHelpers from "./../library/helpers/RouteHelpers";
import Api from "./../library/apis/Api";
import Policy from '@/components/policy/Policy.vue';
import IntercomEmailForm from '@/components/intercom/EmailForm.vue';

export default {
  mixins: [mixin],
  components: {
    AccountContainer,
    Policy,
    IntercomEmailForm
  },
  data() {
    return {
      show: false,
      login: {
        client_id: null,
        client_secret: null,
        grant_type: "client_credentials",
      },
      valid: true,
      busy: false,
      error: false,
      error_message: null,
      rememberLoginCredential: false
    };
  },
  computed: {
    successPage() {
      return this.$route.name === "registered" || this.$route.name === "owner-registered";
    },
    firebaseToken() {
      return this.$store.state.firebaseToken;
    },
    savedCredentials() {
      return localStorage.getItem("__plugzio_user_credentials__")
    },
    isMobileApplication() {
      return this.$root.isMobileApplication
    }
  },
  watch: {
    "login.client_id"(v, oldV) {
      this.$nextTick(() => {
        if (v && oldV != v) this.login.client_id = this.login.client_id.toLowerCase().trim();
      });
    },
    $route() {
      this.checkVerificationUrl();
    },
    rememberLoginCredential(v) {
      if (v === undefined) return
      if (!v && localStorage.getItem("__plugzio_user_credentials__")) {
        localStorage.removeItem("__plugzio_user_credentials__") 
        this.$refs.loginForm.reset();

        this.$store.dispatch("snackbar", {
          message: "Login credentials cleared",
        });
      }
    }
  },
  methods: {
    signIn() {
      if (this.$refs.loginForm.validate()) {
        if (!navigator.onLine) {
          this.error = true;
          this.error_message = "No internet connection. Check your network settings and try again.";
          return;
        }
        this.busy = true;
        this.userLogin({ username: this.login.client_id, password: this.login.client_secret, remember: this.rememberLoginCredential }, () => {
          this.busy = false;
          this.error = true;
          this.error_message = "Invalid Credentials";
        });
      }
    },
    checkVerificationUrl() {
      if (this.$route.path === "/login/verify") {
        this.busy = true;
        this.verify();
      }
    },
    verify() {
      let url = localStorage.getItem("verify");
      if (!url) {
        this.busy = false;
        this.$router.push({ path: "/login" });
        return false;
      }
      Api.runApi("GET", url)
        .then((response) => {
          this.$router.push({ path: `/login/success` });
        })
        .catch((error) => {
          const msg = error.response && error.response.data ? error.response.data : error;
          alert(msg);
          this.$router.push({ path: `/login/verify-error` })
        })
        .finally(() => {
          this.busy = false;
          localStorage.removeItem("verify");
        });
    },
    showIntercom() { 
      this.$store.dispatch('Global/toggleIntercom');
    }
  },
  mounted() {
    // this.$gtm.trackView(this.$route.name, this.$route.path);

    // this.$gtm.trackEvent({
    //   action: 'page_view',
    //   label: 'Page view',
    // });
    this.$store.commit("Global/setReloadOnStateChange", false)
    this.$refs.loginForm.reset();

    this.$nextTick(() => {
      if (this.role === "user" && this.savedCredentials && this.isMobileApplication) {
        const savedCredentials = JSON.parse(this.savedCredentials)
        this.login.client_id = decodeURIComponent(savedCredentials.username)
        this.login.client_secret = decodeURIComponent(savedCredentials.password)
        this.rememberLoginCredential = true
      }

      if (this.role == "owner") {
        if (!RouteHelpers.isLocal()) this.switchMode();
        else if (!this.successPage) this.role == "owner" ? this.$router.push({ name: "owner-login" }) : this.$router.push({ name: "login" });
      }
      this.checkVerificationUrl();
    })
  },
  beforeDestroy() {
    this.$store.commit("Global/setReloadOnStateChange", true)
  }
};
</script>

<style lang="scss">
@import "./../styles/colors";

.login-logo {
  max-width: 280px;
  margin: 20px auto 30px;
  img {
    width: 100%;
  }
}

.login-card {
  max-width: 480px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 8px;
  p {
    font-weight: 300;
  }
}

.auth-header {
  padding: 40px;
  color: #fff;
  a,
  a:hover,
  a:active {
    color: #ffffff !important;
  }
  h1 {
    font-weight: 400;
    line-height: 1em;
    margin-bottom: 16px;
  }
  p {
    @media only screen and (max-width: 960px) {
      font-size: 12px;
    }
  }
  &__user {
    background: $user-color;
  }
  &__owner {
    background: $owner-color;
  }
  &__admin {
    background: $admin-color;
  }
}

.login-area {
  padding: 40px 40px 20px 40px;
}

.login-actions {
  padding: 0 40px 30px;
  @media only screen and (max-width: 960px) {
    font-size: 12.5px;
  }
}
#login-checkbox-container {
  .text {
    line-height: 24px;
  }
}
</style>
