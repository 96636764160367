<template>
  <v-container fluid class="fill-height text-xs-center full-inner-height">
    <v-layout align-center justify-center>
      <v-flex xs12 v-if="!!loading">
        <v-progress-circular class="loading-circle center-margin" size="50" indeterminate color="orange" />
      </v-flex>
      <v-flex xs12 lg7 v-else>
        <v-card v-if="!showManagementInformation && !showTimeBasedBillingDetail && !autoStartSession.show && !activateBle">
          <v-card-title class="flex">
            <h3>Device Activation</h3>
          </v-card-title>
          <v-alert :value="activateError" type="error">
            {{ activateError }}
          </v-alert>
          <v-alert :value="canRetry" type="error">{{ canRetry }}</v-alert>
          <v-card-text v-if="!isDeviceNotRegistered" class="text-xs-center">
            <div v-if="confirmWalletCreate || walletCreate">
              <div v-if="walletCreate">
                <h3 class="mb-2">Creating {{ userAccess.currency }} wallet...</h3>
                <v-progress-circular class="loading-circle mb-3" size="24" indeterminate color="orange" />
              </div>
              <div v-else>You do not have a {{ userAccess.currency }} wallet. We would like to automatically create one for you. Please confirm to proceed.</div>
            </div>
            <div v-else class="text-xs-left">
              <v-flex xs12>
                <div><h4 class="border-bottom">Device</h4></div>
                <div class="mt-2">
                  <p style="overflow-wrap:anywhere;display:flex;align-items:center">
                    <span id="device-activation-managed-message" class="flex flex-1">
                      Pricing and access of device <strong>{{ plugIdentifier }}</strong> is set by
                      <strong v-if="ownerPublicProfile.name">{{ ownerPublicProfile.name }}.</strong>
                      <span v-else>your property manager.</span>
                    </span>
                    <button
                      v-if="ownerPublicProfile.name"
                      id="device-activation-contact-button"
                      class="v-btn--small pa-1 button-onlyicon d-flex align-center ml-2 white-space__nowrap"
                      @click="showManagementInformation = true" 
                    >
                      <v-icon size="18" style="margin: 0" key="icon">contact_mail</v-icon>
                      <span class="ml-2" key="description">Contact</span>
                    </button>
                  </p>
                </div>
              </v-flex>
              <v-flex v-if="isWalletVisible" xs12 class="mt-4">
                <div>
                  <h4 class="border-bottom">Wallet</h4>
                </div>
                <div class="mt-2">
                  <p v-if="!!wallet" style="display:flex" class="align-center">
                    <span>
                      Balance ({{ wallet.currency }}): <strong> {{ floorNumberToTwoDecimal(wallet.balance) | numeral("0,0.00")}}</strong>
                    </span>

                    <v-icon
                      id="low-balance-tooltip"
                      v-if="isBalanceLow"
                      class="tooltip-icon mx-2"
                      color="warning"
                      v-tooltip="{
                        content: 'Low Wallet Balance',
                        placement: 'top-center',
                        trigger: 'click hover',
                      }"
                    >
                      info
                    </v-icon>
                    <TopUpModal :wallet="wallet" @success="onTopUpSuccess" class="ml-2">
                      <button class="v-btn--small pa-1 button-onlyicon">
                        <v-icon size="18" style="margin: 0" key="icon">account_balance_wallet</v-icon>
                        <span class="ml-1" key="description">Top Up</span>
                      </button>
                    </TopUpModal>
                  </p>
                  <p v-else>
                    <span>No Wallet Available</span>
                  </p>
                </div>
              </v-flex>
              <v-flex xs12 class="mt-4 ">
                <div><h4 class="border-bottom">Payment Plan</h4></div>
                <div class="mt-2">
                  <AccessPlan
                    :user-access="userAccess"
                    :loading="activationLoading"
                  />
                  <v-btn
                    v-if="!activationLoading && userAccess && userAccess.haveAccess && userAccess.mode == 2 && userAccess.accessPlan && userAccess.accessPlan.timezone"
                    id="device-activation-plan-detail-button"
                    @click="showTimeBasedBillingDetail = true"
                    class="plugzio-button mb-2 mt-4 wifi-setup-button py-2"
                    block
                    outline
                  >
                    <span style="white-space:break-spaces">More Details</span>
                  </v-btn>
                </div>
              </v-flex>
              <v-flex v-if="userAccess && userAccess.mode == 2 && userAccess.autoStartPossible" xs12 class="mt-4 ">
                <div><h4 class="border-bottom">Automatically Start Sessions</h4></div>

                <div v-if="!autoStartSession.allow" class="mt-2">
                  <p class="text-xs-justify">
                    You are the only user authorized to use this device. Would you like to automatically start sessions when you plug in?
                  </p>
                  <v-btn
                    id="device-activation-auto-start-info-button"
                    @click="autoStartSession.show = true"
                    class="plugzio-button mb-2 mt-4 wifi-setup-button py-2"
                    block
                    outline
                    style="height:100%;"
                  >
                    <span style="white-space:break-spaces">Learn About Automatically Starting Sessions</span>
                  </v-btn>
                  <v-checkbox id="device-activation-auto-start-checkbox" v-model="autoStartSession.allow" required color="success" hide-details class="addwificheckbox">
                    <template v-slot:label>
                      <small style="text-align:justify">
                        I acknowledge the risk and would like to allow sessions to automatically start
                      </small>
                    </template>
                  </v-checkbox>
                </div>
                <div v-else class="mt-2">
                  <p class="text-xs-justify">
                    You have allowed sessions to automatically start. Uncheck the box below to disable this feature
                  </p>

                  <v-checkbox v-model="autoStartSession.allow" required color="success" hide-details class="addwificheckbox">
                    <template v-slot:label>
                      <small style="text-align:justify">
                        Allow sessions to auto start
                      </small>
                    </template>
                  </v-checkbox>
                </div>
              </v-flex>
            </div>
          </v-card-text>
          <v-card-actions v-if="activateError || userAccess">
            <template v-if="insufficientFunds">
              <v-btn id="device-activation-cancel-button" color="gray" flat="flat" @click="$router.push('/')">Cancel</v-btn>
              <v-spacer />
              <TopUpModal :wallet="wallet" @success="onTopUpSuccess" class="ml-2">
                <v-btn color="green darken-1" flat="flat">TOP UP</v-btn>
              </TopUpModal>
            </template>
            <template
              v-else-if="
                userAccess.mode === DeviceHelpers.deviceModes.smart &&
                userAccess.haveAccess &&
                (userAccess.accessPlan || !userAccess.isAnonymous) 
              "
            >
              <v-btn v-if="!!wallet || (!wallet && !confirmWalletCreate)" id="device-activation-cancel-button" color="gray" flat="flat" @click="$router.push('/')">Cancel</v-btn>
              <v-btn v-else color="gray" flat="flat" @click="confirmWalletCreate = false">Back</v-btn>
              <v-spacer />
              <v-btn v-if="!!wallet" :disabled="activationLoading" color="green darken-1" flat="flat" :loading="!!$store.state.loading" @click="confirmActivation()">
                {{ canRetry ? "Try Again" : "Confirm" }}
              </v-btn>
              <v-btn
                v-else
                :id="confirmWalletCreate ? 'device-activation-ok-button' : 'activation-create-wallet'"
                color="green darken-1"
                flat="flat"
                :loading="!!$store.state.loading"
                @click="confirmWalletCreate ? autoCreateWallet() : (confirmWalletCreate = true)"
                :disabled="activationLoading"
              >
                {{ confirmWalletCreate ? "Confirm" : "Create Wallet" }}
              </v-btn>
            </template>
            <template v-else>
              <v-spacer />
              <v-btn color="gray" flat="flat" @click="$router.push('/')">OK</v-btn>
            </template>
          </v-card-actions>
        </v-card>
        <TimeBasedBillingDetail
          v-else-if="showTimeBasedBillingDetail"
          :user-access="userAccess"
          @close="showTimeBasedBillingDetail = false"
        />
        <AutoStartSessionCard
          v-else-if="autoStartSession.show"
          @close="autoStartSession.show = false"
        />
        <ManagementInformation
          v-else-if="showManagementInformation"
          v-model="showManagementInformation"
          :name="ownerPublicProfile.name"
          :phone="ownerPublicProfile.phone"
          :email="ownerPublicProfile.email"
          :message="ownerPublicProfile.message"
        />
        <LowWalletBalanceWarning
          v-model="showLowWalletBalanceWarning"
          :wallet="wallet || {}"
          @confirmed="confirmActivation(false)"
          @topup-done="onTopUpSuccess"
        />
        <!-- <ActivateBluetoothDeviceCard v-else ref="activateBLE" :plug-identifier="plugIdentifier" :activate-error="activateError" @showChanged="(show) => (activateBle = show)" /> -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import helpers from "@/mixins/helpers";
import Api from "@library/apis/Api";
import { calculatePricing, isPricingFree } from "@/library/helpers/paymentPlan"
import { floorNumberToTwoDecimal, DeviceHelpers } from "@/library/helpers";
import AccessPlan from "@/components/AccessPlan.vue";
import ManagementInformation from "@/components/ManagementInformation.vue";
// import ActivateBluetoothDeviceCard from "@/components/bleActivation/ActivateBluetoothDeviceCard";
import TopUpModal from "@/components/modals/TopUp/TopUpModal.vue";
import AutoStartSessionCard from '@/components/activate-device/AutoStartSessionCard.vue';
import TimeBasedBillingDetail from '@/components/activate-device/TimeBasedBillingDetail.vue';
import { INSUFFICIENT_BALANCE_THRESHOLD } from "@/constants"
import LowWalletBalanceWarning from "@/components/modals/LowWalletBalanceWarning.vue";

export default {
  mixins: [helpers],
  components: {
    AccessPlan,
    ManagementInformation,
    /*ActivateBluetoothDeviceCard,*/
    TopUpModal,
    AutoStartSessionCard,
    TimeBasedBillingDetail,
    LowWalletBalanceWarning
  },
  data() {
    return {
      activateError: null,
      activationLoading: false,
      activateBle: false,
      actionSub: null,
      confirmWalletCreate: false,
      canRetry: null,
      loading: false,
      showManagementInformation: false,
      showTimeBasedBillingDetail: false,
      userAccess: null,
      walletCreate: false,
      ownerPublicProfile: {
        name: null,
        email: null,
        phone: null,
        message: null,
      },
      autoStartSession: {
        show: false,
        allow: false,
      },
      showLowWalletBalanceWarning: false
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
      adminActiveView: (state) => state.Admin.activeView,
      managerview: (state) => state.Admin.managerview,
      userview: (state) => state.Admin.userview,
      processingTimeout: (state) => state.Session.processingTimeout,
      wallets: (state) => state.wallets,
    }),
    ...mapGetters("Admin", {
      adminViewAsUser: "viewAsUser",
    }),
    INSUFFICIENT_BALANCE_THRESHOLD: () => INSUFFICIENT_BALANCE_THRESHOLD,
    plugIdentifier() {
      return this.$route.params.plug_id;
    },
    wallet() {
      if (!this.wallets || !this.userAccess || !this.userAccess.currency) return null;
      return this.wallets.find((w) => this.userAccess.currency === w.currency);
    },
    isBalanceLow() {
      return !this.isSessionFree && (this.wallet?.balance || 0) < INSUFFICIENT_BALANCE_THRESHOLD;
    },
    insufficientFunds() {
      return (
        this.activateError === "Insufficient funds in wallet" ||
        (this.wallet && (this.wallet.balance < 0 || (!this.isActivationFree && (this.startSessionCost > this.wallet.balance || this.wallet.balance <= 0))))
      );
    },
    startSessionCost() {
      if (!this.userAccess || !this.userAccess.accessPlan) return 0;
      const conditionalPrice = this.conditionalPricings[0]
      if (conditionalPrice && conditionalPrice.plans) {
        const plan = conditionalPrice.plans[0]
        if (plan) return plan.startSessionCost
      }
      return this.userAccess.accessPlan.startSessionCost;
    },
    conditionalPricings() {
      if (!this.userAccess || !this.userAccess.accessPlan) return [];
      return !!this.userAccess.accessPlan.timezone ? calculatePricing({ paymentPlan: this.userAccess.accessPlan }) : [];
    },
    isActivationFree() {
      if (!this.userAccess || !this.userAccess.accessPlan) return false;
      if ([0, 1].includes(this.userAccess.mode)) return true // don't check always off and on devices
      const isConditionalPlan = !!this.userAccess.accessPlan.timezone
      let { startSessionCost, whRate, hRate } = this.userAccess.accessPlan;
      startSessionCost = startSessionCost || 0;
      whRate = whRate || 0;
      hRate = hRate || 0;
      return (
        !isConditionalPlan ? startSessionCost <= 0 && whRate <= 0 && hRate <= 0 : isPricingFree(this.conditionalPricings, true)
      );
    },
    isSessionFree() {
      if (!this.userAccess || !this.userAccess.accessPlan) return false;
      if ([0, 1].includes(this.userAccess.mode)) return true // don't check always off and on devices
      let { startSessionCost, whRate, hRate } = this.userAccess.accessPlan;
      startSessionCost = startSessionCost || 0;
      whRate = whRate || 0;
      hRate = hRate || 0;
      return (
        startSessionCost <= 0 && whRate <= 0 && hRate <= 0 &&
        isPricingFree(this.conditionalPricings)
      );
    },
    DeviceHelpers: () => DeviceHelpers,
    isDeviceNotRegistered() {
      return this.userAccess && this.userAccess.mode === null
    },
    isWalletVisible() {
      return this.userAccess && this.userAccess.mode == DeviceHelpers.deviceModes.smart && this.userAccess.haveAccess
    },
  },
  watch: {
    insufficientFunds(v) {
      if (v) this.activateError = "Insufficient funds in wallet";
    },
    wallet: {
      handler(v) {
        if (this.insufficientFunds) this.activateError = "Insufficient funds in wallet";
      },
    },
  },
  methods: {
    activationError(error) {
      if (error.response.data && [417, 400].includes(error.response.status)) {
        this.activateError = error.response.data;
        if (this.activateError === "bad wallet for user or owner") {
          this.activateError = `This device charges in ${this.userAccess.currency} currency. Please create a ${this.userAccess.currency} wallet to use this device`;
        }
        if (this.activateError.includes("Load balancing attempt failed")) {
          this.activateError = "Insufficient current to turn on device. Please try again when other devices on the same circuit are not in use.";
        }
      }
    },
    autoCreateWallet() {
      this.confirmWalletCreate = false;
      let newWallet = {
        currency: this.userAccess.currency,
        description: this.userAccess.currency + " Wallet",
      };
      let apiPrefix = this.role
      if (this.role === "admin") {
        if (!!managerview) {
          this.newWallet.ownerUsername = this.managerview.owner_username;
          apiPrefix = "admin/owner"
        }
        if (!!userview) {
          this.newWallet.username = this.adminViewAsUser.username;
          apiPrefix = "admin/user"
        }
      }

      this.$store.dispatch("loading", true);
      this.activateError = false;
      this.walletCreate = true;
      Api.walletsCreate(apiPrefix, newWallet)
        .then(() => {
          this.$store.dispatch("countUserData");
          // this.confirmActivation();
        })
        .catch((error) => {
          this.activationError(error);
        })
        .finally(() => {
          this.walletCreate = false;
          this.$store.dispatch("loading", false);
        });
    },
    confirmActivation(checkBalance = true) {
      if (checkBalance && this.isBalanceLow) {
        this.showLowWalletBalanceWarning = true;
        return
      }
      this.showLowWalletBalanceWarning = false;
      this.$store.dispatch("loading", true);
      this.activateError = false;
      this.canRetry = false;
      Api.sessionsStart(`{"plugIdentifier":"${this.plugIdentifier}"}`)
        .then((data) => {
          const identifier = this.plugIdentifier;
          setTimeout(() => {
            if (data === "Session could not be started") {
              this.canRetry = "Device is offline or unreachable";
            } else {
              this.$store.dispatch("snackbar", { message: data });
              this.$router.push("/");
              this.$store.commit("Session/modifyProcessedDevices", { identifier: identifier, timeout: this.processingTimeout, process: "start" });
            }
            this.$store.dispatch("loading", false);
          }, 2000);
        })
        .catch((error) => {
          this.$store.dispatch("loading", false);
          this.activationError(error);
        })
        .finally(() => {
          const autoStartEnabled = this.userAccess.autoStartEnabled ? this.userAccess.autoStartEnabled : false;
          if (this.autoStartSession.allow != autoStartEnabled) this.setAutoStart(this.plugIdentifier, this.autoStartSession.allow ? 1 : 0);
        });
    },
    getAccess() {
      this.userAccess = {};
      this.activationLoading = true;
      this.ownerPublicProfile = {
        email: null,
        name: null,
        phone: null,
        message: null,
      };
      Api.userPlugAccess(`{"plugIdentifier":"${this.plugIdentifier}"}`)
        .then((data) => {
          this.userAccess = data;
          if (this.userAccess.ownerPublicProfile) {
            this.ownerPublicProfile = {
              email: this.userAccess.ownerPublicProfile.email ? this.userAccess.ownerPublicProfile.email : null,
              name: this.userAccess.ownerPublicProfile.name ? this.userAccess.ownerPublicProfile.name : null,
              phone: this.userAccess.ownerPublicProfile.phone ? this.userAccess.ownerPublicProfile.phone : null,
              message: this.userAccess.ownerPublicProfile.message ? this.userAccess.ownerPublicProfile.message : null,
            };
          }

          // Device not registered
          if (this.userAccess.mode === null) {
            this.activateError = `Device "${this.plugIdentifier}" has not been set up by management. Please contact the property manager for more information.`;
            return
          }

          this.autoStartSession.allow = this.userAccess.autoStartEnabled ? this.userAccess.autoStartEnabled : false;

          // BLE activation, can be ignored for now
          if (this.userAccess.haveAccess && this.userAccess.mode == 3) {
            this.activateBle = true;
            if (this.userAccess.accessPlan && !!this.userAccess.accessPlan.timezone)
              this.activateError = "This device is in Bluetooth mode and you have been assigned an invalid time-based plan. Please contact the manager of the device.";
            this.$nextTick(() => this.$refs.activateBLE.startConnectionInitialization(this.plugIdentifier));
            return;
          }
        })
        .catch((error) => {
          this.activationError(error);
        })
        .finally(() => {
          this.activationLoading = false;
          this.$store.dispatch("loading", false);
        });
    },
    setAutoStart(plug_identifier, value) {
      Api.userPlugSetAutoStart(`{"plugIdentifier":"${plug_identifier}", "autoStart":${value}}`)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.lor(error);
        });
    },
    onTopUpSuccess() {
      this.activateError = false;
      setTimeout(() => { 
        this.$store.dispatch("countUserData");
      }, 1000); 
    },
    floorNumberToTwoDecimal(number) {
      return floorNumberToTwoDecimal(number)
    },
  },
  mounted() {
    this.canRetry = null;
    this.userAccess = null;
    this.confirmWalletCreate = false;
    if (this.wallets.length == 0) this.$store.dispatch("countUserData");

    if (this.$route.query.directlyToBle) {
      this.activateBle = true;
      this.$nextTick(() => this.$refs.activateBLE.connectToBleDevice(this.plugIdentifier));
      return;
    }
    this.$nextTick(() => {
      if (navigator.onLine) this.getAccess();
      else this.$refs.activateBLE.startConnectionInitialization(this.plugIdentifier);
    });
  },
  beforeRouteLeave: function(to, from, next) {
    if (!this.activateBle || to.query.allowRedirect) {
      next();
      return;
    }
    this.$store.dispatch("setBleActivation", { showCloseConfirmation: true });
  },
};
</script>
