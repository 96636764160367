<template>
  <div :class="{ 'full-inner-height': (role === 'owner' && newUser) || (!initialized || !['admin', 'owner', 'user'].includes(role)) }">
    <v-container
      fluid
      v-if="(initialized === true && !newUser) || role == 'admin'"
    >
      <UserDashboard v-if="role === 'user' || (role === 'admin' && activeAdminView == 'userview')" />
      <OwnerDashboard v-else-if="role === 'owner' || (role === 'admin' && !!activeAdminView)" />
      <AdminDashboard v-else-if="role === 'admin'" />
    </v-container>
    <v-container fluid v-else-if="role === 'owner' && newUser" class="fill-height text-xs-center full-inner-height">
      <v-layout align-center justify-center>
        <empty :data="false" :width="550">
          <h4 class="mb-3">Getting Started</h4>
          <div class="notice-description text-xs-left mb-3">
            <div class="mb-3">
              <p class="text-xs-center">
                <strong>Welcome to your new Plugzio account!</strong>
              </p>
              Please click the button below to start setting up your account. We will be going through the following steps:
              <ul class="mt-2">
                <li>Configure your public profile</li>
                <li>Create a wallet to receive payments from the device.</li>
                <li>Register and configure your first device.</li>
                <li>Create a payment plan for the device.</li>
              </ul>
            </div>
            <div class="text-xs-center mt-4 pt-2">
              <v-btn
                class="plugzio-button create-button mx-0 pr-3 pl-3"
                small
                dark
                color="green"
                round
                outline
                :disabled="!hasAccess"
                @click="goToGetStarted"
              >
                GET STARTED
              </v-btn>
            </div>
          </div>
        </empty>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import mixin from "./../mixins";
import UserDashboard from "./Dashboard";
import OwnerDashboard from "./owner/Dashboard";
import AdminDashboard from "./admin/ApiPage";
import Empty from "./../containers/Empty";
import { mapState } from "vuex";
import Api from "./../library/apis/Api";
import { OwnerProxyHelpers } from '@/library/helpers';

export default {
  name: "Landing",
  mixins: [mixin],
  components: {
    UserDashboard,
    OwnerDashboard,
    AdminDashboard,
    Empty,
  },
  computed: {
    ...mapState({
      activeAdminView: (state) => state.Admin.activeView,
    }),
    newUser() {
      return this.role == "owner" ? this.$store.getters.newUser : false;
    },
    initialized() {
      return this.$store.state.initialized;
    },
    firebaseToken() {
      return this.$store.state.firebaseToken;
    },
    hasAccess() {
      return OwnerProxyHelpers.isHaveAccessToFeature("GET_STARTED")
    }
  },
  methods: {
    checkFirebase() {
      let savedFirebaseToken = localStorage.getItem("firebaseToken");
      if (this.firebaseToken !== savedFirebaseToken) this.subscribeForNotifications();
    },
    subscribeForNotifications(mode = "subscribe") {
      if (!this.firebaseToken) return false;

      Api.userPushNotification(mode, {
        googleToken: this.firebaseToken,
      })
        .then(() => localStorage.setItem("firebaseToken", this.firebaseToken))
        .catch((error) => {
          localStorage.removeItem("firebaseToken");
          console.log("Push notifications token failed: ", error);
        });
    },
    goToGetStarted() {
      if (!this.hasAccess) return;
      this.$router.push("/owner/get-started");
    },
  },
  watch: {
    firebaseToken() {
      if (this.role === "user" && this.access_token) this.checkFirebase();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkFirebase();
    });
  },
};
</script>

<style lang="scss" scoped>
.warning-icon {
  width: 20px;
  height: 20px;
  margin: 0 4px -4px;
}
</style>
