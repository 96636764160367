<template>
  <account-container :busy="busy">
    <v-form ref="regform" @submit="register()" v-model="valid" v-show="!registered">
      <v-layout column>
        <div :class="[`auth-header__${role}`, 'auth-header']">
          <h1 class="mb-0" v-if="!registered">{{ roleName }} Registration</h1>
          <template v-if="!registered">
            <p class="mt-2 mb-0">
              <a href="#" @click.stop.prevent="owner = !owner">
                To access Plugzio dashboard and its features, please register an account
              </a>
            </p>
          </template>
          <h1 class="mb-0" v-else>Verification Sent</h1>
        </div>
        <v-alert id="error" :value="error" type="error" dismissible>
          <template v-if="error === 'Username exists'">
            This e-mail is already registered. Please click <a href="#/login" class="white--text">HERE</a> to login or <a href="#" class="white--text">HERE</a> if you forgot your
            password.
          </template>
          <template v-else>{{ error }}</template>
        </v-alert>
        <v-flex class="login-area pb-0">
          <v-text-field
            id="register-email"
            outline
            label="Email"
            ref="emailBox"
            prepend-inner-icon="account_circle"
            v-model="user.email"
            :error="error ? true : false"
            autocomplete="false"
            type="email"
            :rules="[rules.email]"
            @keyup.enter.stop="register()"
            @keydown.space.prevent
            required
          />
          <div id="register-password-container">
            <v-text-field
              id="register-password"
              outline
              label="Password"
              ref="regPasswordBox"
              prepend-inner-icon="vpn_key"
              :append-icon="show1 ? 'visibility' : 'visibility_off'"
              v-model="user.password"
              :error="error ? true : false"
              :type="show1 ? 'text' : 'password'"
              autocomplete="false"
              :rules="[rules.password]"
              @keyup.enter.stop="register()"
              @click:append="show1 = !show1"
              required
            />
          </div>
          <div id="register-confirm-password-container">
            <v-text-field
              id="register-confirm-password"
              outline
              label="Confirm Password"
              ref="regPasswordBox2"
              prepend-inner-icon="vpn_key"
              :append-icon="show2 ? 'visibility' : 'visibility_off'"
              v-model="user.password_confirm"
              :error="error ? true : false"
              :type="show2 ? 'text' : 'password'"
              autocomplete="false"
              :rules="[rules.password_confirm]"
              @keyup.enter.stop="register()"
              @click:append="show2 = !show2"
              required
            />
          </div>
          <div class="text-xs-left mb-3 hidden-md-and-up">
            I have read and agreed to:
          </div>
          <div class="text-xs-left mb-4">
            <v-checkbox
              id="check-terms-of-service"
              class="mt-0 mb-2"
              v-model="user['terms-of-service']"
              required
              color="success"
              hide-details
              :rules="[(v) => !!v || '']"
            >
              <template v-slot:label>
                <span class="hidden-sm-and-down">I have read and agreed to</span>
                <a
                  id="terms-of-service"
                  href="#"
                  class="plugzio-color--text"
                  @click.stop.prevent="openPolicyText('terms-of-service')"
                >
                  &nbsp;Terms of Service
                </a>
              </template>
            </v-checkbox>
            <v-checkbox
              id="check-privacy-policy"
              class="mt-0 mb-2"
              v-model="user['privacy-policy']"
              required
              color="success"
              hide-details
              :rules="[(v) => !!v || '']"
            >
              <template v-slot:label>
                <span class="hidden-sm-and-down">I have read and agreed to</span>
                <a
                  id="privacy-policy"
                  href="#"
                  class="plugzio-color--text"
                  @click.stop.prevent="openPolicyText('privacy-policy')"
                >
                  &nbsp;Privacy Policy
                </a>
              </template>
            </v-checkbox>

            <v-checkbox
              v-if="countryCode === 'IN'"
              id="check-return-policy"
              class="ma-0"
              v-model="user['return-policy']"
              required
              color="success"
              hide-details
              :rules="[(v) => !!v || '']"
            >
              <template v-slot:label>
                <span class="hidden-sm-and-down">I have read and agreed to</span>
                <a
                  id="return-policy"
                  href="#"
                  class="plugzio-color--text"
                  @click.stop.prevent="openPolicyText('return-policy')"
                >
                  &nbsp;Return Policy
                </a>
              </template>
            </v-checkbox>
          </div>
        </v-flex>
        <v-flex class="pb-4">
          <v-btn id="register" class="plugzio-button" outline round @click.stop.prevent="register()">Register</v-btn>
          <div class="mt-2">
            <v-btn id="back-to-sign-in" color="#0d920d" flat round @click="$router.push({ name: `${roleSetting.routePrefix}login` })">Back to Sign In</v-btn>
          </div>
          <div>
            <v-btn id="forgot-password" small color="#777777" flat round @click="$router.push({ name: `${roleSetting.routePrefix}forgot-password` })">Forgot Password</v-btn>
          </div>
        </v-flex>
      </v-layout>
    </v-form>
    <template v-if="registered">
      <v-layout column>
        <div :class="[`auth-header__${role}`, 'auth-header']">
          <h1 class="mb-0">Verification Sent</h1>
        </div>
        <v-alert :value="error" type="error" dismissible>
          <template>{{ error }}</template>
        </v-alert>
        <v-flex class="login-area pa-5">
          A verification e-mail has been sent to <strong>{{ this.user.username }}</strong
          >. Please check the e-mail to complete your account registration.
        </v-flex>
      </v-layout>
      <v-layout row class="login-actions">
        <v-flex xs-6 text-xs-left>
          <button id="resend-activation" @click="register()">Resend Activation</button>
        </v-flex>
        <v-flex xs-6 text-xs-right>
          <button id="back-to-sign-in" @click="backToSignIn()">Back to Sign In</button>
        </v-flex>
      </v-layout>
    </template>

    <Policy class="mb-4" />
  </account-container>
</template>

<script>
import mixin from "@/mixins";
import AccountContainer from "@/containers/Account";
import RouteHelpers from "@/library/helpers/RouteHelpers";
import Api from "@/library/apis/Api";
import { mapGetters } from 'vuex';
import { TextHelpers } from "@/library/helpers"
import Policy from "@/components/policy/Policy.vue";

export default {
  mixins: [mixin],
  components: {
    AccountContainer,
    Policy
  },
  data() {
    return {
      user: {
        email: null,
        username: null,
        password: null,
        password_confirm: null,
        "terms-of-service": false,
        "privacy-policy": false,
        "return-policy": false,
        redirect: null,
      },
      rules: {
        password_confirm: (value) => {
          if (value) {
            return this.user.password === this.user.password_confirm || "Passwords does not match";
          } else {
            return "";
          }
        },
      },
      registered: JSON.parse(localStorage.getItem("register")),
      valid: true,
      show1: false,
      show2: false,
      busy: false,
      error: false,
    };
  },
  computed: {
    ...mapGetters("Global", {
      countryCode: "countryCode"
    }),
    redirect() {
      return `${UI_URL}#/${this.roleSetting.urlPrefix}login/success`;
    },
  },
  methods: {
    register() {
      if (this.countryCode !== "IN") this.user["return-policy"] = true 
      this.error = false;
      if (this.$refs.regform.validate()) {
        this.busy = true;
        this.user.redirect = this.$root.isMobileApplication ? `#/${this.roleSetting.urlPrefix}login/success` : this.redirect;
        Api.signup(this.role, this.user)
          .then((response) => {
            this.busy = false;
            this.registered = this.user;
            localStorage.setItem("register", JSON.stringify(this.user));
          })
          .catch((error) => {
            this.busy = false;
            this.error = error.response.data;
          });
      }
    },
    backToSignIn() {
      this.user = {
        email: null,
        username: null,
        password: null,
        password_confirm: null,
        "terms-of-service": false,
        "privacy-policy": false,
        "return-policy": false,
        redirect: null,
      };
      this.registered = false;
      localStorage.removeItem("register");
      this.$router.push({ name: `${this.roleSetting.routePrefix}login` });
    },
    openPolicyText(policyName) {
      this.$store.commit("Global/setState", {
        globalDialog: {
          show: true,
          title: TextHelpers.getPolicyTitle(policyName),
          content: `<div class="document">${ TextHelpers.getPolicyText(policyName, this.countryCode) }</div>`,
          showCancel: true,
          cancelText: "Disagree",
          onCanceled: () => {
            this.user[policyName] = false;
          },
          showConfirm: true,
          confirmText: "Agree",
          onConfirmed: () => {
            this.user[policyName] = true
          }
        }
      })
    }
  },
  watch: {
    user() {
      this.error = null;
    },
    "user.email"() {
      this.$nextTick(() => {
        if (this.user.email) this.user.email = this.user.email.toLowerCase();
        this.user.username = this.user.email;
      });
    },
  },
  mounted() {
    this.$refs.regform.reset();
    if (this.registered) this.user = this.registered;
    if (this.role == "owner") {
      if (RouteHelpers.isLocal()) this.switchMode();
      else this.owner ? this.$router.push({ name: "owner-register" }) : this.$router.push({ name: "register" });
    }
  },
};
</script>
