<template>
  <v-container fluid :class="{ 'fill-height full-inner-height' : noRecent || sessionsLoading }">
    <v-layout row wrap>
      <v-flex xs4 sm2 class="text-xs-left page-toolbar">
        <div class="d-flex align-center" style="flex-wrap: wrap">
          <h1 style="flex-grow: 0!important; font-size: 25px;">{{ title }}</h1>
        </div>
      </v-flex>
      <v-flex xs12 class="text-xs-center" v-show="sessionsLoading">
        <v-progress-circular indeterminate color="orange" :size="50"></v-progress-circular>
      </v-flex>

      <v-flex 
        xs8
        sm10
        class="text-xs-right page-toolbar"
        v-show="!sessionsLoading"
      >
        <DataExporter
          v-show="!sessionsLoading"
          class="mr-2 d-inline-block"
          title="Export Session Data"
          :data="exportableCSV"
          :filename="`Session_History_Data (${$moment.unix(storedTime.since).format('YYYY-MM-DD')} - ${$moment.unix(storedTime.till).format('YYYY-MM-DD')})_${this.$moment().format('YYYYMMDDHHmmss')}.csv`"
          tooltipText="Exported file will be in CSV format. Exported data is based on excatly what is seen on the session history page during the time of export. Changing the calendar's date range will affect what is exported."
        >
          Date From:
          <strong>{{ $moment.unix(storedTime.since).format("YYYY-MM-DD") }}</strong>
          <br />Date To:
          <strong>{{ $moment.unix(storedTime.till).format("YYYY-MM-DD") }}</strong>
          <div class="modal-description mt-2">
            Note: You can change the calendar date to filter the data you want exported.
          </div>
        </DataExporter>
        <v-menu offset-y style="margin: 5px;">
          <v-btn slot="activator" value="status" small flat class="page-toolbar-btn">
            <span class="hidden-sm-and-down">
              Status: &nbsp;
              <strong>{{ filter.status.label }}</strong>
            </span>
            <v-icon class="hidden-md-and-up" :class="{ 'success--text': filter.status.property === 1, 'warning--text': filter.status.property === 0 }">power</v-icon>
          </v-btn>
          <v-list hover>
            <v-list-tile
              class="pointer"
              :class="{ 'menu-active': filter.status.property === item.property }"
              v-for="(item, index) in filter.statuses"
              :key="index"
              @click="filter.status = item"
            >
              <small>{{ item.label }}</small>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-menu offset-y>
          <v-btn slot="activator" value="order" small flat class="page-toolbar-btn">
            <span class="hidden-sm-and-down">
              Sort: &nbsp;
              <strong>{{ filter.sorting.label }}</strong>
            </span>
            <v-icon class="hidden-md-and-up">format_list_bulleted</v-icon>
          </v-btn>
          <v-list hover>
            <v-list-tile
              class="pointer"
              :class="{ 'menu-active': filter.sorting.property === item.property }"
              v-for="(item, index) in filter.sort"
              :key="index"
              @click="filter.sorting = item"
            >
              <small>{{ item.label }}</small>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-btn flat small class="page-toolbar-btn" @click="filter.desc = !filter.desc" :disabled="!filter.sorting.property">
          <v-icon :class="{ 'rotate-icon': !filter.desc }">sort</v-icon>
        </v-btn>
        <DateRange class="date-range" :with-timezone="role == 'admin'" timezone-text-position="left" />
      </v-flex>
      <v-flex xs12 class="text-xs-center" v-if="noRecent">
        <empty :data="false">
          <h1>No Recent Sessions</h1>
          <p>
            Start a session on
            <strong>
              <a class="plugzio-color--text" @click.stop.prevent="$router.push({ name: `${roleSetting.routePrefix}dashboard` })">Dashboard</a>
            </strong>
            by entering a device #
          </p>
        </empty>
      </v-flex>
      <template v-else-if="!sessionsLoading">
        <v-flex xs12 class="ml-2" v-if="sessionsLoaded">{{ sessionsLoaded }}</v-flex>
        <v-flex xl3 lg4 md6 xs12 v-for="(session, index) in filteredSessions" :key="index">
          <v-card class="session-tile session-history ma-2" :class="{ 'session-active': session.active }">
            <v-card-title class="mb-0">
              <div>
                <strong>Device ID: {{ session.plug_identifier }}</strong>
                <br />
                <span class="session-id">Session ID: {{ session.id }}</span>
              </div>
              <v-spacer></v-spacer>
              <v-chip small :color="variables.sessions.statusColors[session.statusCode]" text-color="white">{{ session.status }}</v-chip>
              <v-btn small dark color="green" class="plugzio-button view-details-button ma-0 ml-2" round outline @click.stop.prevent="openDetails(session)">VIEW DETAILS</v-btn>
            </v-card-title>
            <v-card-text class="py-0 text-xs-center">
              <light-timeline :items="session.timeline">
                <template slot="tag" slot-scope="{ item }">{{ item.tag }}</template>
                <template slot="symbol" slot-scope="{ item }">
                  <v-icon :class="item.class">power</v-icon>
                </template>
                <template slot="content" slot-scope="{ item }">{{ item.content }}</template>
              </light-timeline>

              <v-icon
                v-if="session.statusCode == 2"
                class="tooltip-icon session-stale-info"
                v-tooltip="{
                  content: 'Session has not been updated recently. Device maybe offline or powered off.',
                  placement: 'top-center',
                  trigger: 'click hover',
                }"
              >
                info
              </v-icon>
            </v-card-text>
            <v-layout row wrap class="session-details">
              <v-flex xs4 class="session-detail py-3 text-xs-center">
                <h2>{{ session.duration }}</h2>
                <small>Duration</small>
              </v-flex>
              <v-flex xs4 class="session-detail py-3 text-xs-center">
                <h2>{{ session.consumption }}</h2>
                <small>Consumption</small>
              </v-flex>
              <v-flex xs4 class="session-detail py-3 text-xs-center">
                <h2>{{ session.amount | numeral("0,0.00") }}</h2>
                <small>
                  Amount
                  <span v-if="session.wallet">({{ session.wallet.currency }})</span>
                </small>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </template>
    </v-layout>
    <SessionLog :selected="selectedSession" :show-email-and-description="false" />
  </v-container>
</template>

<script>
import SessionHelpers from "@/library/helpers/SessionHelpers";
import mixin from "@/mixins";
import sessions from "@/mixins/sessions";
import { mapState, mapGetters } from "vuex";
import TimezoneHelper from "@/library/helpers/TimezoneHelper";
import { cloneDeep as _cloneDeep } from "lodash-es";
import TimeHelper from "@/library/helpers/TimeHelpers";
import variables from "@/mixins/variables";
import DateRange from "@/components/modals/DateRange";
import SessionLog from "@/components/modals/SessionLog";
import DataExporter from "@/components/modals/DataExporter.vue";
import Empty from "@/containers/Empty";

export default {
  mixins: [mixin, sessions, variables],
  components: {
    DateRange,
    SessionLog,
    Empty,
    DataExporter,
  },
  data() {
    return {
      filter: {
        desc: true,
        status: {
          label: "All",
          property: "all",
        },
        statuses: [
          {
            label: "All",
            property: "all",
          },
          {
            label: "On",
            property: 1,
          },
          {
            label: "Off",
            property: 0,
          },
          {
            label: "Stale",
            property: 2,
          },
        ],
        sorting: {
          label: "Start Time",
          property: "starttime",
        },
        sort: [
          {
            label: "Device ID",
            property: "plug_identifier",
          },
          {
            label: "Session ID",
            property: "id",
          },
          {
            label: "Start Time",
            property: "starttime",
          },
          {
            label: "End Time",
            property: "endtime",
          },
          {
            label: "Duration",
            property: "duration_raw",
          },
          {
            label: "Consumption",
            property: "total_consumption",
          },
          {
            label: "Amount",
            property: "total_cost",
          },
        ],
      },
      selectedSession: null,
      actionSub: null,
      isOpenSessionIdDialogOpened: false
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
    }),
    ...mapGetters("Admin", {
      viewAsUser: "viewAsUser",
    }),
    filteredSessions() {
      const sessions = _cloneDeep(this.$store.getters.sessions);
      const filters = {
        status: this.filter.status.property,
        sort_by: this.filter.sorting.property,
        sort_direction_desc: this.filter.desc,
      };
      return SessionHelpers.filter(sessions, filters);
    },
    noRecent() {
      return this.sessionsLoaded === "No recent sessions";
    },
    title() {
      return this.role == "admin" && this.viewAsUser ? `${this.viewAsUser.username} > Session History` : "Session History";
    },
    username() {
      if (this.role != "admin" || !this.viewAsUser) return localStorage.getItem("email");
      return this.viewAsUser.username;
    },
    exportableCSV() {
      let data = ``;
      let consumption = 0;
      let duration = 0;
      let expense = {}; 

      for (let i = 0; i < this.filteredSessions.length; i++) {
        const session = this.filteredSessions[i];
        
        const currency = session.wallet.currency;
        const totalCost = parseFloat(session.total_cost);

        if (!expense[currency]) {
          expense[currency] = 0;
        }
        expense[currency] += totalCost;

        data += `"` + session.plug_identifier.toString() + `",`;
        data += `"` + session.id.toString() + `",`;
        data += `"` + session.wallet.currency + `",`;
        data += `"${session.plug_payment_access_plan ? JSON.stringify(session.plug_payment_access_plan).replaceAll('"', '""') : ""}",`;
        data += `"` + this.$moment.unix(session.starttime).format("YYYY-MM-DD HH:mm:ss") + `",`;
        data += `"` + (session.active ? this.$moment.unix(session.updated).format('YYYY-MM-DD HH:mm:ss') : this.$moment.unix(session.endtime).format("YYYY-MM-DD HH:mm:ss")) + `",`;
        data += `"` + session.duration + `",`;
        data += `"` + session.consumption.replace(" kWh", "") + `",`;
        data += `"` + session.amount + `"\n`;

        consumption += session.total_consumption;
        duration += session.duration_raw;
      }
      consumption = (consumption / 1000).toFixed(3);
      duration = TimeHelper.getTotalDuration(duration);
      
      let totalExpense = '';
        for (const currency in expense) {
          totalExpense += `Total Expense (${currency}):,"${expense[currency].toFixed(2)}",,,,,,,\n`;
        }

      return (
        `User:,${this.username},,,,,,,\n` +
        `Date From (YYYY-MM-DD):,"${this.$moment.unix(this.timeSpan.since).format("YYYY-MM-DD HH:mm:ss")}",,,,,,,\n` +
        `Date To (YYYY-MM-DD):,"${this.$moment.unix(this.timeSpan.till).format("YYYY-MM-DD HH:mm:ss")}",,,,,,,\n` +
        `Time Zone (GMT):,"${TimezoneHelper.getOffset()}",,,,,,,\n` +
        `Total Sessions:,"${this.filteredSessions.length}",,,,,,,\n` +
        `Total Consumption (kWh):,"${consumption}",,,,,,,\n` +
        `Total Duration (HH:MM:SS):,"${duration}",,,,,,,\n` +
        `${totalExpense}\n` +
        `Device ID,Session ID,Payment Plan - Currency,Payment Plan - Details,Session - Start Time (YYYY-MM-DD HH:MM:SS),Session - End Time (YYYY-MM-DD HH:MM:SS),Session - Duration (HH:MM:SS),Session - Consumption (kWh),Cost ($)\n` +
        `${data}`
      );
    },
  },
  watch: {
    sessions() {
      if (this.$route.query.openSessionIdDialog && !this.isOpenSessionIdDialogOpened) {
        this.$nextTick(() => {
          const session = this.filteredSessions.find((session) => session.id == this.$route.query.openSessionIdDialog);
          if (session) {
            this.isOpenSessionIdDialogOpened = true;
            this.openDetails(session);
          }
        });
      }
    }
  },
  methods: {
    openDetails(session) {
      this.selectedSession = null;
      this.$nextTick(() => {
        this.selectedSession = session;
      });
    },
    handleQueryString() {
      const query = this.$route.query;
      if (query.status) {
        const status = this.filter.statuses.find((item) => item.label.toLowerCase() == query.status.toLowerCase());
        if (status) this.filter.status = status;
      }
    }
  },
  mounted() {
    if (this.role == "admin" && !this.viewAsUser) return this.$router.push(`/admin/userview`);
    this.initSessions();
    this.handleQueryString()
    this.actionSub = this.$store.subscribeAction((action) => {
      if (action.type === "setTime" && (this.$route.name === "sessions" || this.$route.name === "admin-userview-sessions")) this.getSessions();
    });
  },
  beforeDestroy() {
    if (typeof this.actionSub == "function") this.actionSub();
  },
};
</script>

<style lang="scss">
.plug-on-color {
  color: #4caf50 !important;
}

.plug-off-color {
  color: orange !important;
}

.line-container {
  color: #606c76;
  font-size: 14px;
  font-family: "Roboto", Helvetica, Arial, sans-serif !important;
  box-sizing: border-box;
  position: relative;
  list-style: none;
  margin: 0.5rem auto;
  padding-left: 5.6rem;
  display: inline-block;
}

.line-container .item-symbol {
  left: -1.82rem !important;
  top: 0.82rem !important;
  background: none !important;
}

.line-container .item-tag {
  left: -7rem !important;
  top: 1.11rem !important;
  text-align: right !important;
}

.line-container .line-item {
  padding-left: 0.4rem !important;
  font-size: 12px;
  text-align: left;
}

.session-id {
  font-size: 0.85em;
  color: gray;
}

.session-history {
  border: 0;
}

.session-detail {
  h2 {
    color: #444;
    font-size: 1em;
    font-weight: 500;
  }
}

.session-detail:last-child {
  border-right: none;
}

.session-details {
  small {
    color: #949fa8;
    text-transform: uppercase;
  }
}

.view-details-button {
  min-width: auto;
  font-size: 13px;
  padding: 0 12px;
  height: 24px;
}
</style>
<style scoped lang="scss">
.session-stale-info {
  top: initial;
  margin-top: 10px;
}
</style>
