<template>
  <v-container fluid>
    <v-layout row wrap>
      <!-- <v-flex xs6>
        <v-card>
            <v-card-text>
                <v-text-field
                    v-model="logs"
                    label="Number of logs"
                    outline
                    autocomplete="false"
                    required
                    type="number"
                    min="1"
                    @keyup.enter="process()"
                ></v-text-field>
            </v-card-text>
            <v-card-text>
                <h4>Result</h4>
                    <code>{{ token }}</code>
                <h4>Result</h4>
                    <code>{{ result }}</code>
            </v-card-text>
        </v-card>
      </v-flex> -->
      <code>
        {{ testLog }}
      </code>
    </v-layout>
  </v-container>
</template>

<script>
import mixin from "./../mixins";
import Api from "@library/apis/Api";

export default {
  mixins: [mixin],
  data() {
    return {
      logs: 1,
      result: null,
      loading: false,
      token: null,
      gXToken: "279f71b4bafde074383afe0150a56e411cee9bc61efeb4e91402b74bc132aeb2",
    };
  },
  computed: {
    testLog() {
      let logs = [];
      let count = 0;
      let to = 12;
      let time = this.$moment().unix() - 600 * to;
      do {
        logs.push({
          consumption: 1 + count / 2,
          current: 1,
          id: 2949,
          logitem_timestamp: time + 600 * count,
          session_id: 376,
          voltage: 0,
        });
        count = count + 1;
      } while (count <= to);
      return logs;
    },
  },
  methods: {
    auth() {
      const data = {
        fobIdentifier: "FOB-1",
        plugIdentifier: "test-paul",
      };

      Api.plugRequestSession(data)
        .then((data) => {
          this.token = data;
          // this.process()
        })
        .catch((error) => {
          this.result = error.response.data;
        });
    },
    process() {
      if (!this.logs) return;

      let requests = [];
      let count = 0;
      let timestamp = this.$moment().unix();
      do {
        const data = {
          timestamp: timestamp - 600 * count,
          consumption: 1 + count,
          voltage: 110,
          current: 0.9,
          particleId: "FOB-1",
        };
        requests.push(
          Api.plugSessionUpdate(data, {
            "X-token": this.gXToken,
          })
        );
        count++;
      } while (count < this.logs);
      Promise.all(requests)
        .then(([data]) => {
          this.result = data;
        })
        .catch((error) => {
          this.result = error.response.data;
        });
    },
  },
  mounted() {
    // this.auth()
  },
};
</script>
