<template>
  <div>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12>
          <h1 id="title">{{ title }}</h1>
        </v-flex>

        <template v-if="activeAdminView === 'managerview'">
          <v-flex md4 xs12 >
            <ChangeManagerEmailForm />
          </v-flex>
          <v-flex md4 xs12 >
            <MultiManagement /> 
          </v-flex>
        </template>
        <v-flex md4 xs12 v-else-if="activeAdminView === 'userview'">
          <ChangePasswordUserView />
        </v-flex>
        <template v-else>
          <v-flex md4 xs12>
            <SettingsCard v-if="role !== 'admin'"/>
            <ChangePassword />
          </v-flex>
          <v-flex md4 xs12 v-if="role == 'owner'">
            <OwnerPublicProfileSetting />
          </v-flex>
          <v-flex
            v-if="role == 'owner'"
            md4 
            xs12
          >
            <MultiManagement /> 
          </v-flex> 
        </template>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import mixin from "@/mixins";
import { mapGetters, mapState } from "vuex"
import ChangeManagerEmailForm from '@/components/forms/ChangeManagerEmailForm.vue';
import ChangePasswordUserView from "./admin/ChangePasswordUserView.vue";
import ChangePassword from "@/components/ChangePassword.vue";
import OwnerPublicProfileSetting from "@/components/OwnerPublicProfileSetting.vue";
import SettingsCard from "@/components/SettingsCard.vue"; 
import MultiManagement from "@/components/multiManagement/MultiManagement.vue"; 

export default {
  mixins: [mixin],
  components: {
    ChangePassword,
    OwnerPublicProfileSetting,
    SettingsCard,
    ChangeManagerEmailForm,
    ChangePasswordUserView,
    MultiManagement,
  },
  computed: {
    ...mapState({
      activeAdminView: (state) => state.Admin.activeView,
      userview: (state) => state.Admin.userview,
      managerview: (state) => state.Admin.managerview,
    }),
    ...mapGetters("Admin", {
      deviceview: "formattedDeviceView",
      viewAsOwner: "viewAsOwner",
    }),
    title() {
      if (this.role == "admin") {
        if (this.activeAdminView == "managerview " && !!this.viewAsOwner) return `${this.viewAsOwner.username} > Configuration`;
        if (this.activeAdminView == "userview"  && !!this.userview)  return `${this.userview.username} > Configuration`;
        return "Configuration";
      }
    }
  },
  mounted() {
    if (this.activeAdminView === 'managerview' && !this.managerview.owner_username) this.$router.push("/admin/managerview")
  }
}
</script>
