<template>
  <v-layout row wrap>
    <v-flex xs3 class="text-xs-left page-toolbar">
      <h1 id="title" class="mt-2" style=" font-size: 25px;">{{ title }}</h1>
    </v-flex>
    <v-flex xs9 class="text-xs-right page-toolbar">
      <DateRange :is-owner="role == 'owner'" class="date-range" style="margin: 5px;" :with-timezone="role == 'admin'" timezone-text-position="left" />
    </v-flex>
    <v-flex lg5 sm12>
      <UserStatsTotals v-if="responsive" />
    </v-flex>
    <v-flex lg12 sm12>
      <UserStatsTotals v-if="!responsive" />
    </v-flex>
    <v-flex md4 xs12>
      <v-card class="activate-plug-tile ma-2">
        <v-card-title>
          <h3 id="activate-plugzio-tile">Activate Plugzio</h3>
          <v-spacer></v-spacer>
          <v-chip id="activate-plugzio-how-to" small :color="showHelp ? '#333333' : '#9a9a9a'" text-color="white" class="ma-0 help-button" @click="showHelp = !showHelp">
            How To
          </v-chip>
        </v-card-title>
        <v-alert :value="qrError" type="error">{{ qrError }}</v-alert>
        <v-card-text class="text-xs-center">
          <v-form ref="activateDeviceForm" @submit="startActivation()">
            <v-text-field
              id="activate-plugzio-field"
              v-model="plugIdentifier"
              @keyup.enter="$router.push(`/activate-device/${plugIdentifier}`)"
              label="Enter device # here"
              outline
              prepend-inner-icon="power"
              hint="Characters 'A' to 'K', '0' to '9' and '.' only"
              persistent-hint
              autocomplete="false"
              required
              :error="!!qrError"
              ref="plugBox"
              class="mb-0 activate-plugzio-input"
              :disabled="!!viewAsUser"
              :rules="[validators.required]"
            >
              <template v-slot:append>
                <v-fade-transition leave-absolute>
                  <v-progress-circular v-if="busy" size="24" color="info" indeterminate></v-progress-circular>
                  <qr-scanner @captured="captured" />
                </v-fade-transition>
              </template>
            </v-text-field>
            <v-btn id="activate-plugzio-active-button" small class="plugzio-button activate-button mt-2" outline round @click.stop="startActivation" :disabled="!!viewAsUser">
              ACTIVATE
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex md8 xs12>
      <RecentSessionCard />
      <activated-bluetooth-device v-if="false" class="mt-10"></activated-bluetooth-device>
    </v-flex>

    <v-dialog v-model="showHelp" max-width="360">
      <v-card>
        <v-card-title>
          <h3>How to activate Plugzio</h3>
          <v-spacer></v-spacer>
          <v-btn small icon @click="showHelp = false" class="ma-0">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="showHelp" class="mt-1">
          <p>
            Start using Plugzio by plugging in before entering the plug #. The plug # can be found on the front of the device under the QR code. In the example image the plug # is
            "XX123" circled in red.
          </p>
          <div class="text-xs-center mt-3">
            <img src="./../assets/front-sample.jpg" class="help-sample-image" />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import mixin from "@/mixins";
import sessions from "@/mixins/sessions";
import validators from "@/library/helpers/validators";
import DateRange from "@/components/modals/DateRange";
import UserStatsTotals from "@/components/graphs/UserStatsTotals";
import ActivatedBluetoothDevice from "@/components/bleActivation/ActivatedBluetoothDeviceTile";
import QrScanner from "@/components/modals/QrScanner";
import RecentSessionCard from "@/components/recent-sessions/RecentSessionCard.vue";

export default {
  mixins: [mixin, sessions],
  components: {
    DateRange,
    UserStatsTotals,
    ActivatedBluetoothDevice,
    QrScanner,
    RecentSessionCard,
  },
  data() {
    return {
      plugIdentifier: null,
      showHelp: false,
      actionSub: null,
      qrError: null,
    };
  },
  computed: {
    ...mapState({
      busy: (state) => state.busy,
      responsive: (state) => state.responsive,
    }),
    ...mapGetters("Admin", {
      viewAsUser: "viewAsUser",
    }),
    title() {
      return this.role == "admin" && this.viewAsUser ? `${this.viewAsUser.username} > Dashboard` : "Dashboard";
    },
    validators: () => validators,
  },
  watch: {
    plugIdentifier() {
      if (this.plugIdentifier && !this.plugIdentifier.includes("aryawifi")) {
        this.$nextTick(() => {
          this.qrError = null;
          if (!this.plugIdentifier.match(/-/)) {
            this.plugIdentifier = this.plugIdentifier.toUpperCase().replace(/[^0-9a-k.]/gi, "");
          }
        });
      } else {
        this.qrError = null;
      }
    },
    $route() {
      this.checkExternalId();
    },
  },
  methods: {
    captured(evt) {
      if (!evt || evt == "clear") {
        this.plugIdentifier = null;
        return;
      }
      if (!evt.match(/my.plugz.io\/activate-plug\?external_id=/)) {
        this.qrError = "Non Plugzio QR Code detected";
        return;
      }
      let code = evt.split("=");
      this.plugIdentifier = code[1];
      console.log(code[1]);
      this.$router.push(`/activate-device/${this.plugIdentifier}`);
    },
    checkExternalId() {
      if (this.role == "admin") return;
      let plugId = localStorage.getItem("external-id");
      this.$nextTick(() => {
        if (plugId) {
          this.plugIdentifier = plugId;
          localStorage.removeItem("external-id");
          this.activate();
        }
      });
    },
    startActivation() {
      if (this.$refs.activateDeviceForm.validate()) {
        this.$router.push(`/activate-device/${this.plugIdentifier}`);
      }
    },
  },
  mounted() {
    if (this.role == "admin" && !this.viewAsUser) return this.$router.push(`/admin/userview`);
    this.plugIdentifier = null;
    this.checkExternalId();
    this.initSessions();
    this.actionSub = this.$store.subscribeAction((action) => {
      if (action.type === "onResume") this.checkExternalId();
      if (action.type === "onPause") localStorage.removeItem("external-id");
      if (action.type === "setTime" && (this.$route.name === "dashboard" || this.$route.name === "admin-userview-dashboard")) this.getSessions();
    });
  },
  beforeDestroy() {
    if (typeof this.actionSub == "function") this.actionSub();
  },
};
</script>

<style lang="scss">
.plug-list {
  padding: 10px;
}

.plug-tile-details {
  font-size: 0.75em;
  color: #949fa8;
  font-weight: 400;
  padding-left: 16px;
  @media only screen and (max-width: 560px) {
    padding-left: 4px;
  }
}

.plug-tile-details {
  .black--text {
    color: #444;
  }
}

.activate-button {
  margin-top: -5px;
  min-width: 100px;
}

.session-button {
  min-width: 100px;
}

.session-tile {
  border: 1px solid;
  background: #f8f8f8;
  border-radius: 4px;
  margin-bottom: 15px;
  border-left: 4px solid lightgray;
  overflow: hidden;
}

.session-tile {
  .v-list__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 88px;
    @media only screen and (max-width: 560px) {
      padding: 0px 8px;
    }
  }
}

.recent-plugs {
  padding-top: 14px;
  padding-bottom: 14px;
}

.session-active {
  border-color: #4caf50;
  background: rgba(221, 236, 221, 0.4);
}

.session-active {
  .plug-avatar {
    color: #ffffff !important;
  }
}

.session-tile:last-child {
  margin-bottom: 0;
}

.session-list {
  padding-top: 8px;
  padding-bottom: 8px;
}

.plug-avatar {
  width: 78px;
  height: 78px;
  background: #f8f8f8;
  margin-top: 17px;
  border-radius: 4px;
  border: 1px solid #dadada;
  @media only screen and (max-width: 560px) {
    height: 64px;
    width: 64px;
  }
}
.v-list__tile__avatar {
  min-width: 78px;
  min-height: 78px;
  @media only screen and (max-width: 560px) {
    min-height: 64px;
    min-width: 64px;
  }
}

.v-avatar {
  height: 78px !important;
  width: 78px !important;
  @media only screen and (max-width: 560px) {
    height: 64px !important;
    width: 64px !important;
  }
}

.v-list__tile__sub-title {
  font-size: 12px;
}
.v-card {
  cursor: auto;
}

.button-off {
  border-color: gray !important;
  color: gray !important;
}

.help-sample-image {
  max-width: 320px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
