<template>
  <account-container :busy="busy">
    <v-layout column>
      <div :class="[`auth-header__${role}`, 'auth-header']">
        <h1 class="mb-0" v-if="emailSubmitted">{{ roleName }} Update Password</h1>
        <h1 class="mb-0" v-else>
          <span v-if="emailSent">Email Sent</span>
          <span v-else>{{ roleName }} Forgot Password</span>
        </h1>
      </div>
      <v-alert :value="error" type="error" dismissible>{{ error }}</v-alert>
      <v-flex class="login-area">
        <template v-if="emailSubmitted">
          <v-flex v-if="changeSuccess" class="login-area py-2 px-5">
            <h3 class="success--text mb-1">Password updated successfully.</h3>
            <p>Signing you in automatically. Please wait...</p>
            <v-flex v-if="autoLoginBusy">
              <v-progress-circular class="loading-circle" size="25" indeterminate color="orange" />
            </v-flex>
          </v-flex>
          <v-form v-show="!changeSuccess" ref="changePasswordForm" @submit="changePassword()" v-model="validChangeForm">
            <v-text-field
              outline
              label="New Password"
              prepend-inner-icon="vpn_key"
              :append-icon="show1 ? 'visibility' : 'visibility_off'"
              v-model="user.password"
              :error="error ? true : false"
              :type="show1 ? 'text' : 'password'"
              autocomplete="false"
              :rules="[rules.password]"
              @keyup.enter.stop="changePassword()"
              @click:append="show1 = !show1"
              required
              :disabled="busy"
            ></v-text-field>
            <v-text-field
              outline
              label="Confirm New Password"
              prepend-inner-icon="vpn_key"
              :append-icon="show2 ? 'visibility' : 'visibility_off'"
              v-model="user.confirmPassword"
              :error="error ? true : false"
              :type="show2 ? 'text' : 'password'"
              autocomplete="false"
              :rules="[rules.password_confirm]"
              @keyup.enter.stop="changePassword()"
              @click:append="show2 = !show2"
              required
              :disabled="busy"
            ></v-text-field>
            <v-btn class="plugzio-button" outline round @click.stop.prevent="changePassword()" :disabled="busy">SUBMIT</v-btn>
          </v-form>
        </template>
        <template v-else>
          <v-flex v-if="emailSent" class="login-area py-2 px-5">We've sent you an e-mail with a link to reset your password.</v-flex>
          <v-form v-show="!emailSent" ref="submitEmailForm" @submit="submitEmail()" v-model="validEmail">
            <v-text-field
              id="forgot-password-email"
              outline
              label="E-mail"
              ref="usernameBox"
              prepend-inner-icon="account_circle"
              v-model="user.username"
              :error="error ? true : false"
              autocomplete="false"
              :rules="[rules.email]"
              @keyup.enter.stop="submitEmail()"
              @input="user.username != null ? user.username.toLowerCase() : null"
              required
            ></v-text-field>
            <v-btn id="submit" class="plugzio-button" outline round @click.stop.prevent="submitEmail()" :disabled="busy">SUBMIT</v-btn>
          </v-form>
          <div class="mt-2">
            <v-btn id="register-new-account" color="#0d920d" flat round @click="$router.push({ name: `${roleSetting.routePrefix}register` })">Register New Account</v-btn>
          </div>
          <div>
            <v-btn id="back-to-sign-in" color="#0d920d" flat round @click="$router.push({ name: `${roleSetting.routePrefix}login` })">Back to Sign In</v-btn>
          </div>
        </template>
        <Policy class="mt-4" />
      </v-flex>
    </v-layout>
  </account-container>
</template>

<script>
import mixin from "./../mixins";
import AccountContainer from "./../containers/Account";
import RouteHelpers from "./../library/helpers/RouteHelpers";
import Api from "@library/apis/Api";
import Policy from '@/components/policy/Policy.vue';

export default {
  mixins: [mixin],
  components: {
    AccountContainer,
    Policy
  },
  data() {
    return {
      user: {
        username: null,
        password: null,
        confirmPassword: null,
        verifyjwt: null,
        redirect: "",
      },
      rules: {
        password_confirm: (value) => {
          if (value) {
            return this.user.password === this.user.confirmPassword || "Passwords does not match";
          } else {
            return "";
          }
        },
      },
      error: false,
      busy: false,
      autoLoginBusy: true,
      show1: false,
      show2: false,
      show3: false,
      validEmail: true,
      validChangeForm: true,
      changeSuccess: false,
      emailSent: false,
      iframeUrl: "about:blank",
    };
  },
  computed: {
    newPasswordRoute() {
      return this.$route.name === "new-password" || this.$route.name === "owner-new-password";
    },
    emailSubmitted() {
      return this.$route.params.status || false;
    },
    verifyUrl() {
      return this.$route.params.status === "verify";
    },
    jwt() {
      if (this.newPasswordRoute) {
        return window.location.hash ? window.location.hash.split("verifyjwt=")[1] : false;
      }
      return false;
    },
  },
  methods: {
    submitEmail() {
      if (this.$refs.submitEmailForm.validate()) {
        this.busy = true;
        this.error = false;
        let redirectURL = this.$root.isMobileApplication ? "" : UI_URL;
        this.user.redirect = `${redirectURL}#/${this.roleSetting.urlPrefix}forgot-password/${encodeURIComponent(this.user.username)}/`;

        Api.resetPassword(this.role, this.user)
          .then((response) => {
            this.busy = false;
            this.emailSent = true;
            localStorage.setItem("user", this.user.username);
          })
          .catch((error) => {
            this.busy = false;
            if (error) {
              this.error = error.response.data.replace("Username", "E-mail");
              if (this.error === "Owner doesn't exists") this.error = "Management does not exist";
            }
          });
      }
    },
    changePassword() {
      if (this.$refs.changePasswordForm.validate()) {
        this.busy = true;
        this.error = false;
        Api.setPassword(this.role, this.user)
          .then((response) => {
            this.busy = false;
            this.autoLogin();
          })
          .catch((error) => {
            this.busy = false;
            if (error) this.error = error.response.data;
          });
      }
    },
    autoLogin() {
      this.changeSuccess = true;
      setTimeout(() => {
        this.userLogin({ username: this.emailSubmitted, password: this.user.password, remember: false }, () => {
          this.busy = false;
          this.error = true;
        });
      }, 1500);
    },
    verify() {
      this.busy = true;
      let url = localStorage.getItem("reset-password");
      if (!url) {
        this.busy = false;
        this.$router.push({ path: "/password-reset" });
        return false;
      }
      Api.runApi("GET", url, {}, { "X-not-redirect": "true" })
        .then((data) => {
          this.busy = false;
          let rawUrl = data.split("/forgot-password/")[1];
          let urlData = rawUrl.split("/#verifyjwt=");
          this.user.username = decodeURIComponent(urlData[0]);
          this.user.verifyjwt = urlData[1];
          this.$router.push({ path: "/forgot-password/" + rawUrl });
        })
        .catch((error) => {
          this.busy = false;
          const msg = error.response && error.response.data ? error.response.data : error;
          alert(msg);
          this.$router.push({ path: "/forgot-password" });
        })
        .finally(() => {
          localStorage.removeItem("reset-password");
        });
    },
  },
  watch: {
    $route() {
      this.verifyUrl ? this.verify() : (this.busy = false);
    },
    "user.username"() {
      if (this.user.username) {
        this.$nextTick(() => {
          this.user.username = this.user.username.toLowerCase();
        });
      }
    },
  },
  mounted() {
    this.busy = false;
    if (this.role == "owner") {
      if (!this.newPasswordRoute) {
        if (!RouteHelpers.isLocal()) this.switchMode();
        else this.role == "owner" ? this.$router.push({ name: "owner-forgot-password" }) : this.$router.push({ name: "forgot-password" });
      }
    }

    // if (!this.$root.isMobileApplication && this.newPasswordRoute && this.jwt && this.emailSubmitted) {
    if (this.newPasswordRoute && this.jwt && this.emailSubmitted) {
      this.user.verifyjwt = this.jwt;
      this.user.username = this.emailSubmitted;
      console.log("user:", this.user);
    } else if (this.verifyUrl) {
      this.verify();
    } else {
      this.$router.push({ name: `${this.roleSetting.routePrefix}forgot-password` });
    }

    if (!this.emailSubmitted) {
      try {
        this.$refs.submitEmailForm.reset();
      } catch (e) {}
    }
  },
};
</script>

<style lang="scss">
.hidden-iframem {
  border: 0;
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
}
</style>
