<template>
    <blank>
        <h2 class="header">PRIVACY POLICY</h2>
        <div v-html="text"></div>
    </blank>
</template>

<script>
import Blank from './../containers/Blank'
import { TextHelpers } from "@/library/helpers"
import { mapGetters } from 'vuex'

export default {
  components: {
    Blank
  },
  computed: {
    ...mapGetters("Global", {
      countryCode: 'countryCode'
    }),
    text() {
      return TextHelpers.getPolicyText("terms-of-service", this.countryCode)
    }
  },
}
</script>
